@import "./assets/scss/app.scss";

// * {
//   -ms-overflow-style: none;
// }
// ::-webkit-scrollbar {
//   display: none;
// }

body {
  overflow-y: scroll;
  width: 100%;
}

.page-wrapper .page-header .header-wrapper .nav-right .profile-dropdown {
  top: 57px;
  left: -32px;
  padding: 0 10px;
}

